<template>
  <div id="maintainPlan">
    <el-dialog
      :title="maintainPlanFormTitle"
      width="680px"
      :visible.sync="maintainPlanDialogVisible"
      :close-on-click-modal="false"
      @close="maintainPlanDialogClose"
    >
      <el-form
        ref="maintainPlanFormRef"
        :model="maintainPlanForm"
        :rules="maintainPlanFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="deviceId">
              <el-select
                v-model="maintainPlanForm.deviceId"
                placeholder="请选择设备"
                clearable
                @change="deviceChange"
              >
                <el-option
                  v-for="item in deviceList"
                  :key="item.id"
                  :label="item.deviceName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备编号" prop="deviceNo">
              <el-input v-model="maintainPlanForm.deviceNo" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号" prop="spec">
              <el-input v-model="maintainPlanForm.spec" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产厂家" prop="manufacturer">
              <el-input v-model="maintainPlanForm.manufacturer" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计划月份" prop="planMonth">
              <el-date-picker
                v-model="maintainPlanForm.planMonth"
                placeholder="请选择计划月份"
                type="month"
                value-format="yyyy-MM"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="人员" prop="personnel">
              <el-input v-model="maintainPlanForm.personnel" placeholder="请输入人员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保养内容" prop="maintainContent">
              <el-input
                v-model="maintainPlanForm.maintainContent"
                placeholder="请输入保养内容"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="maintainPlanForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制人" prop="editor">
              <el-input v-model="maintainPlanForm.editor" placeholder="请输入编制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="maintainPlanForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编制日期" prop="editTime">
              <el-date-picker v-model="maintainPlanForm.editTime" placeholder="请选择编制日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approverTime">
              <el-date-picker v-model="maintainPlanForm.approverTime" placeholder="请选择批准日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="maintainPlanDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="maintainPlanFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备名称">
        <el-input v-model="searchForm.deviceName" placeholder="请输入设备名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="maintainPlanPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="manufacturer" label="生产厂家" />
      <el-table-column prop="planMonth" label="计划月份" />
      <el-table-column prop="maintainContent" label="保养内容" />
      <el-table-column prop="personnel" label="人员" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="maintainPlanPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMaintainPlan, deleteMaintainPlan, updateMaintainPlan, selectMaintainPlanInfo, selectMaintainPlanList } from '@/api/device/maintainPlan'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      maintainPlanDialogVisible: false,
      maintainPlanFormTitle: '',
      maintainPlanForm: {
        id: '',
        deviceId: '',
        deviceName: '',
        deviceNo: '',
        spec: '',
        manufacturer: '',
        planMonth: '',
        maintainContent: '',
        personnel: '',
        remarks: '',
        editor: '',
        editTime: '',
        approver: '',
        approverTime: ''
      },
      maintainPlanFormRules: {
        deviceId: [{ required: true, message: '设备不能为空', trigger: ['blur', 'change']}]
      },
      maintainPlanPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceName: ''
      },
      deviceList: []
    }
  },
  created () {
    selectBaseInfoList().then(res => {
      this.deviceList = res.list
    })
    selectMaintainPlanList(this.searchForm).then(res => {
      this.maintainPlanPage = res
    })
  },
  methods: {
    maintainPlanDialogClose () {
      this.$refs.maintainPlanFormRef.resetFields()
    },
    maintainPlanFormSubmit () {
      if (this.maintainPlanFormTitle === '维护保养计划详情') {
        this.maintainPlanDialogVisible = false
        return
      }
      this.$refs.maintainPlanFormRef.validate(async valid => {
        if (valid) {
          if (this.maintainPlanFormTitle === '新增维护保养计划') {
            await addMaintainPlan(this.maintainPlanForm)
          } else if (this.maintainPlanFormTitle === '修改维护保养计划') {
            await updateMaintainPlan(this.maintainPlanForm)
          }
          this.maintainPlanPage = await selectMaintainPlanList(this.searchForm)
          this.maintainPlanDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.maintainPlanFormTitle = '新增维护保养计划'
      this.maintainPlanDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaintainPlan(row.id)
        if (this.maintainPlanPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.maintainPlanPage = await selectMaintainPlanList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.maintainPlanFormTitle = '修改维护保养计划'
      this.maintainPlanDialogVisible = true
      this.selectMaintainPlanInfoById(row.id)
    },
    handleInfo (index, row) {
      this.maintainPlanFormTitle = '维护保养计划详情'
      this.maintainPlanDialogVisible = true
      this.selectMaintainPlanInfoById(row.id)
    },
    selectMaintainPlanInfoById (id) {
      selectMaintainPlanInfo(id).then(res => {
        this.maintainPlanForm.id = res.id
        this.maintainPlanForm.deviceId = res.deviceId
        this.maintainPlanForm.deviceName = res.deviceName
        this.maintainPlanForm.deviceNo = res.deviceNo
        this.maintainPlanForm.spec = res.spec
        this.maintainPlanForm.manufacturer = res.manufacturer
        this.maintainPlanForm.planMonth = res.planMonth
        this.maintainPlanForm.maintainContent = res.maintainContent
        this.maintainPlanForm.personnel = res.personnel
        this.maintainPlanForm.remarks = res.remarks
        this.maintainPlanForm.editor = res.editor
        this.maintainPlanForm.editTime = res.editTime
        this.maintainPlanForm.approver = res.approver
        this.maintainPlanForm.approverTime = res.approverTime
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaintainPlanList(this.searchForm).then(res => {
        this.maintainPlanPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaintainPlanList(this.searchForm).then(res => {
        this.maintainPlanPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaintainPlanList(this.searchForm).then(res => {
        this.maintainPlanPage = res
      })
    },
    deviceChange (value) {
      if (value) {
        let device = this.deviceList.find(item => item.id === value)
        this.maintainPlanForm.deviceName = device.deviceName
        this.maintainPlanForm.deviceNo = device.deviceNo
        this.maintainPlanForm.spec = device.spec
        this.maintainPlanForm.manufacturer = device.manufacturer
      } else {
        this.maintainPlanForm.deviceName = ''
        this.maintainPlanForm.deviceNo = ''
        this.maintainPlanForm.spec = ''
        this.maintainPlanForm.manufacturer = ''
      }
    }
  }
}
</script>

<style>
</style>
