import axios from '@/common/axios'
import qs from 'qs'

export function addMaintainPlan (data) {
  return axios({
    method: 'post',
    url: '/device/maintainPlan/add',
    data: qs.stringify(data)
  })
}

export function deleteMaintainPlan (id) {
  return axios({
    method: 'delete',
    url: '/device/maintainPlan/delete/' + id
  })
}

export function updateMaintainPlan (data) {
  return axios({
    method: 'put',
    url: '/device/maintainPlan/update',
    data: qs.stringify(data)
  })
}

export function selectMaintainPlanInfo (id) {
  return axios({
    method: 'get',
    url: '/device/maintainPlan/info/' + id
  })
}

export function selectMaintainPlanList (query) {
  return axios({
    method: 'get',
    url: '/device/maintainPlan/list',
    params: query
  })
}
